<template>
<div>
  <v-card-text>
    <v-form ref="form">
      <v-row align="center">
        <v-col cols="12" md="3">
          <label>{{ $t('reviews.place') }}</label>
          <v-text-field v-model="searchInputValue" prepend-inner-icon="$magnify" outlined dense single-line hide-details="auto" />
        </v-col>
        <v-col cols="12" md="2">
          <DatePicker v-model="params.dateFrom" :label="$t('filters.dateFrom')" />
        </v-col>
        <v-col cols="12" md="2">
          <DatePicker v-model="params.dateTo" :label="$t('filters.dateTo')" />
        </v-col>
        <v-col cols="12" md="2" offset-md="3" class="d-flex justify-end">
          <v-checkbox v-model="params.showOnlyMine" hide-details class="mt-3">
          <template #label>
              <span>{{ $t('freightList.showOnlyMain') }}</span>
          </template>
          </v-checkbox>
        </v-col>
       </v-row>
    </v-form>
  </v-card-text>
  <v-divider />
  <data-table
    ref="WaitingToReview"
    :headers="headers"
    endpoint="/Transaction/GetContractorTransactionsWaitingToReview"
    :additionalParams="{ dateFrom: params.dateFrom, dateTo: params.dateTo, showOnlyMine: params.showOnlyMine }"
    :search="searchInputValue"
    serverPagination>
    <template v-slot:item.state="{ item }">
      <div v-if="userCommonData.companyId === item.provider.companyId" class="d-flex align-center" :class="item.isReviewRemainderAvailable ? 'pointer' : ''" @click="item.isReviewRemainderAvailable ? sendReminder(item.orderId) : ''">
        <v-icon size="26">$provider</v-icon>
        <div class="ml-4">
          <h4>{{ $t('reviews.provider') }}</h4>
          <span v-if="userCommonData.id === item.provider.userId" :class="item.isReviewRemainderAvailable ? '' : 'separator--text'">{{ $t('reviews.remainder') }}</span>
        </div>
      </div>
      <div v-else class="d-flex align-center" :class="item.isReviewRemainderAvailable ? 'pointer' : ''" @click="item.isReviewRemainderAvailable ? sendReminder(item.orderId) : ''">
        <v-icon size="26">$client</v-icon>
        <div class="ml-4">
          <h4>{{ $t('reviews.client') }}</h4>
          <span v-if="userCommonData.id === item.client.userId" :class="item.isReviewRemainderAvailable ? '' : 'separator--text'">{{ $t('reviews.remainder') }}</span>
        </div>
      </div>
    </template>
    <template v-slot:item.reviewAvailableTo="{ item }">
      {{ countDays(item.reviewAvailableTo) }}
    </template>
    <template v-slot:[`item.forwarder`]="{ item }">
      <span class="text">{{ item.forwarder.fullName }}</span>
    </template>
    <template v-slot:item.loadingPlaces="{ item }">
      <div class="d-flex align-center">
        <div class="d-flex align-center">
        <CountryFlag :country='item.loadingPlaces[0].country' class="mr-1"/>
        <div class="d-flex flex-column">
            <span>
            {{ item.loadingPlaces[0].country.toUpperCase() }}, <span>{{ item.loadingPlaces[0].postalCode }}</span> <br/>
            {{ item.loadingPlaces[0].city }}
            </span>
            <div class="lightText--text">
            <span>{{ item.loadingPlaces[0].date | dateFormat }} </span>
            <span v-if="item.loadingPlaces[0].from && item.loadingPlaces[0].to">{{ item.loadingPlaces[0].from }} - {{ item.loadingPlaces[0].to }}</span>
            </div>
            <div v-if="item.loadingDistanceFromFilter">
            <span>{{ item.loadingDistanceFromFilter }}</span>
            </div>
        </div>
        </div>
        <v-tooltip v-if="item.loadingPlaces.length > 1" bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="more-data ml-2"> +{{ item.loadingPlaces.length - 1 }} </div>
        </template>
        <div v-for="(location, index) in item.loadingPlaces" :key="index">
            <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
            <CountryFlag :country="location.country" class="mr-1" />
            <div class="d-flex flex-column">
                <span>{{ location.country.toUpperCase() }}, {{ location.postalCode }} {{ location.city }}</span>
                <span>{{ location.date | dateFormat }}<span v-if="location.from && location.to"> {{ location.from }} - {{ location.to }}</span></span>
            </div>
            </div>
        </div>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:item.unloadingPlaces="{ item }">
    <div v-if="item.unloadingPlaces.length > 0" class="d-flex align-center">
        <div class="d-flex align-center">
        <CountryFlag :country='item.unloadingPlaces[0].country' class="mr-1"/>
        <div class="d-flex flex-column">
            <div>
            <span>
                {{ item.unloadingPlaces[0].country.toUpperCase() }}, {{ item.unloadingPlaces[0].postalCode }}<br/>
                {{ item.unloadingPlaces[0].city }}
            </span>
            <div class="lightText--text">
                <span>{{ item.unloadingPlaces[0].date | dateFormat }} </span>
                <span v-if="item.unloadingPlaces[0].from && item.unloadingPlaces[0].to">{{ item.unloadingPlaces[0].from }} - {{ item.unloadingPlaces[0].to }}</span>
            </div>
            <div v-if="item.unloadingDistanceFromFilter">
                <span>{{ item.unloadingDistanceFromFilter }}</span>
            </div>
            </div>
        </div>
        </div>
        <v-tooltip v-if="item.unloadingPlaces.length > 1" bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="more-data ml-2">+{{ item.unloadingPlaces.length - 1 }}</div>
        </template>
        <div v-for="(location, index) in item.unloadingPlaces" :key="index">
            <div v-if="index !== 0" class="d-flex align-center" style="line-height: 1.25">
            <CountryFlag :country="location.country" class="mr-1" />
            <div class="d-flex flex-column">
                <span>{{ location.country.toUpperCase() }}, {{ location.postalCode }} {{ location.city }}</span>
                <span>{{ location.date | dateFormat }} <span v-if="location.from && location.to">{{ location.from }} - {{ location.to }}</span></span>
            </div>
            </div>
        </div>
        </v-tooltip>
    </div>
    <div v-else class="ml-2">
        {{ $t('myVehicle.anyDestination') }}
    </div>
    </template>
    <template v-slot:item.dealDate="{ item }">
    <div class="text-center">
        {{ item.dealDate | dateFormat('DD.MM.YYYY') }} <br/>
        {{ item.dealDate | dateFormat('HH:mm') }}
    </div>
    </template>
    <template v-slot:item.contractor="{ item }">
      <div v-if="item.contractor" class="d-flex align-center cursor-pointer">
        <StatusAvatar :userId="item.contractor.id" :size="26" showStatus class="mr-2"></StatusAvatar>
        <div class="d-flex flex-column">
        <div>{{ item.contractor.name }} {{ item.contractor.surname }}</div>
          <v-tooltip bottom min-width="320px">
            <template v-slot:activator="{ on, attrs }">
            <div class="lightText--text" v-bind="attrs" v-on="on">{{ item.contractor.company.name | trimText(20) }}</div>
            </template>
            <v-row class="mt-1">
              <v-col cols="6">
                <p>{{ item.contractor.company.name }}<br />
                  NIP: {{ item.contractor.company.vatNumber }}</p>
                <p>{{ $t('offerDetails.address') }}:<br />
                {{ item.contractor.company.street }}<br />
                {{ item.contractor.company.zipCode }}, {{ item.contractor.company.city }}</p>
              </v-col>
              <v-col cols="6">
                {{ $t('offerDetails.averageRating') }}:
                <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averageRating" class="mb-4"></v-rating>
                  {{ $t('offerDetails.paymentRating') }}:
                <v-rating length="5" size="16" color="accent" :value="+item.contractor.company.averagePaymentRating"></v-rating>
              </v-col>
            </v-row>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template v-slot:[`item.chatIcon`]="{ item }">
      <v-hover v-slot="{ hover }">
        <v-icon v-if="item.contractor" icon @click.stop="startConversationWithUser(item)" :color="hover ? 'primary' : ''" size="18">$conversation</v-icon>
      </v-hover>
    </template>
    <template v-slot:no-data>
      {{ $t("rating.noData") }}
    </template>
  </data-table>
</div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { startConversation } from '@/signalr/chat'
import Services from '@/services'
import DatePicker from '@/components/forms/DatePicker'
import CountryFlag from 'vue-country-flag'
import StatusAvatar from '@/components/StatusAvatar'
export default {
  name: 'Waiting',
  components: {
    DatePicker,
    CountryFlag,
    StatusAvatar
  },
  data () {
    return {
      params: {
        dateFrom: null,
        dateTo: null,
        showOnlyMine: false
      },
      searchInputValue: '',
      headers: [
        {
          text: this.$t('reviews.state'),
          value: 'state',
          sortable: false
        },
        {
          text: this.$t('reviews.time'),
          align: 'center',
          value: 'reviewAvailableTo'
        },
        {
          text: this.$t('reviews.forwarder'),
          value: 'forwarder'
        },
        {
          text: this.$t('reviews.loading'),
          value: 'loadingPlaces'
        },
        {
          text: this.$t('reviews.unloading'),
          value: 'unloadingPlaces'
        },
        {
          text: this.$t('reviews.date'),
          align: 'center',
          value: 'dealDate'
        },
        {
          text: this.$t('reviews.contractor'),
          align: 'start',
          sortable: false,
          value: 'contractor'
        },
        {
          value: 'chatIcon',
          width: '10',
          sortable: false
        }
      ]
    }
  },
  async created () {
  },
  computed: {
    ...mapGetters({
      userCommonData: 'userCommonData'
    })
  },
  methods: {
    startConversationWithUser (row) {
      startConversation(row.contractor.id, row.orderId)
    },
    countDays (end) {
      const currenDate = moment(new Date())
      const endDate = moment(end)
      return endDate.diff(currenDate, 'days') ? endDate.diff(currenDate, 'days') + ' dni' : endDate.diff(currenDate, 'hours') + ' godzin'
    },
    async sendReminder (id) {
      this.loading = true
      try {
        await Services.post('Transaction/SendTransactionReviewReminder', { orderId: id })
        await this.$refs.WaitingToReview.getData()
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
